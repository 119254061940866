import React, {Component} from "react";
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import {green, red, yellow} from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

class Status extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            res: "No response",
            email: '',
            device_id: '',
            // sensors: [["", "", ""], ["", "", ""], ["", "", ""], ["", "", ""]],
            errorMessage: ''
        };
        this.props.action('settings');
        // const res = JSON.parse(`{"success":true,"email":"ece4012hoa@gmail.com","air_temperature":["77","ok"],"water_temperature":["0","severe"],"water_level":["100","warning"],"uv_level":["75","warning"]}`);
        // console.log("Debug res: " + JSON.stringify(res));
        // const newSensors = [['Air Temperature', `${res.air_temperature[0]}\u00B0F`, res.air_temperature[1]],
        //     ['Water Temperature', `${res.water_temperature[0]}\u00B0F`, res.water_temperature[1]],
        //     ['Water Level', `${res.water_level[0]}%`, res.water_level[1]],
        //     ['UV Index', res.uv_level[0], res.uv_level[1]]];
        // this.state.email = res.email;
        // this.state.sensors = newSensors;
        this.logout = this.logout.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        //On page load get aquaponic status from server
        this.onRefresh();
    }

    render() {
        return (
            <div className="Status">
                <h1>Status</h1>
                {this.state.errorMessage.length === 0 ? null :
                    <div style={{marginBottom: '30px', color: 'red'}}>{this.state.errorMessage}</div>}
                {/*<p>{this.state.res}</p> DEBUG*/}
                {this.state.sensors === undefined ? null : <SensorList sensors={this.state.sensors}/>}
                {this.state.email === undefined || this.state.email ===  '' ? null :
                    <div style={{marginTop: '30px'}}>
                        <a href="#" onClick={this.logout}>Logged in as {this.state.email}</a>
                    </div>}
            </div>
        );
    }

    onRefresh() {
        fetch("https://hookedonaquaponics.ece4012.gatech.edu/status.php", {
            method: 'POST',
            credentials: "include",
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            }
        }).then(response => {
            if (response.status !== 200) {
                console.log(`An error occurred. Status code: ${response.status}`);
                this.setState({
                    errorMessage: `An error occurred. Status code: ${response.status}`,
                });
            } else {
                let parent = this;
                response.json().then(function (res) {
                    console.log("Response: ", res);
                    parent.setState({
                        res: JSON.stringify(res)
                    });
                    if (res.hasOwnProperty('error')) {
                        parent.setState({errorMessage: res.error});
                        if (res.hasOwnProperty('email')) {
                            parent.setState({
                                email: res.email
                            });
                        }
                    } else {
                        const newSensors = [['Air Temperature', `${res.air_temperature[0]}\u00B0F`, res.air_temperature[1]],
                            ['Water Temperature', `${res.water_temperature[0]}\u00B0F`, res.water_temperature[1]],
                            ['Water Level', `${res.water_level[0]}%`, res.water_level[1]],
                            ['UV Index', res.uv_level[0], res.uv_level[1]],
                            ['Hours of Light', Math.round(res.hours_of_light[0] * 100) / 100, res.hours_of_light[1]],
                            ['Color Red', res.color[0], 'ok'],
                            ['Color Green', res.color[1], 'ok'],
                            ['Color Blue', res.color[2], 'ok'],
                        ];
                        parent.setState({
                            email: res.email
                        });
                        parent.setState({
                            sensors: newSensors
                        });
                    }
                });
            }
        });
    }

    logout() {
        fetch("https://hookedonaquaponics.ece4012.gatech.edu/logout.php", {
            method: 'POST',
            credentials: "include",
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            }
        }).then(response => {
            if (response.status !== 200) {
                console.log(`An error occurred. Status code: ${response.status}`);
                this.setState({
                    errorMessage: `An error occurred. Status code: ${response.status}`,
                });
            } else {
                let parent = this;
                //DEBUG: {"success":true,"email":"ece4012hoa@gmail.com","air_temperature":["77","ok"],"water_temperature":["0","severe"],"water_level":["100","warning"]}
                response.json().then(function (res) {
                    console.log(res);
                    if (res.success) {
                        window.location.href = "/#/login";
                    } else {
                        parent.setState({
                            errorMessage: response.error,
                        });
                    }
                });
            }
        });
    }
}

class SensorList extends Component {
    cardStyle = () => {
        return {
            backgroundColor: '#F3F3F3',
            width: '50%'
        }
    };

    render() {
        return (
            <Grid container direction='column' alignItems='center' spacing={1} justify='center'>
                {this.props.sensors.map((sensor) => (
                    <Card style={this.cardStyle()}>
                        <Sensor key={sensor[0]} sensor={sensor}/>
                    </Card>
                ))}
            </Grid>
        );
    }
}

export class Sensor extends Component {
    render() {
        if (this.props.sensor.size === 0) {
            console.warn("Empty sensor given");
            return (<br/>);
        }
        return (
            <Grid container item xs={10} alignItems='center' direction='row' justify='center'>
                <Grid item xs={6}>
                    <p><strong>{this.props.sensor[0]}</strong></p>
                </Grid>
                <Grid item xs={2}>
                    <p>{this.props.sensor[1]}</p>
                </Grid>
                <Grid item xs={2}>
                    <RadioButtonCheckedIcon style={{
                        marginLeft: '50px',
                        color: this.props.sensor[2].toLowerCase() === 'ok' ? green['A400']
                            : this.props.sensor[2].toLowerCase() === 'warning' ? yellow['A400'] : red['A400']
                    }}/>
                </Grid>
            </Grid>
        )
    }
}

export default Status;