import React, {Component, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Slider from '@material-ui/core/Slider';
import Button from "@material-ui/core/Button";
import {TextField} from "@material-ui/core";
import {number} from "prop-types";

class Settings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            res: "No response",
            errorMessage: '',
        };
        this.props.action('back');
        this.onSubmit = this.onSubmit.bind(this);
        this.fetchSettings = this.fetchSettings.bind(this);
        this.fetchSettings(); //JSON.parse(`{"success":true,"email":"ece4012hoa@gmail.com","settings":{"device_id":"1000200030004000","air_temperature_warning_min":"30","air_temperature_warning_max":"100","air_temperature_emergency_min":"10","air_temperature_emergency_max":"120","water_temperature_warning_min":"30","water_temperature_warning_max":"100","water_temperature_emergency_min":"10","water_temperature_emergency_max":"120","water_level_warning_min":"80","water_level_warning_max":"100","water_level_emergency_min":"50","water_level_emergency_max":"120","uv_level_warning_min":"6","uv_level_warning_max":"8","uv_level_emergency_min":"0","uv_level_emergency_max":"100","hours_of_light_needed":"9","feed_fish_at":"12","take_picture_every":"12"}}`);
    }


    render() {
        return (
            <div style={{overFlowX: 'hidden', maxWidth: '100%', marginBottom: '5vw'}}>
                <h1>Settings</h1>
                {this.state.errorMessage === '' ? null : <div style={{marginBottom: '30px', color: 'red'}}>
                    {this.state.errorMessage}</div>}
                {this.state.settings === undefined ? null : <Settingslist settings={this.state.settings}/>}
                <Button onClick={this.onSubmit} variant="contained" color="primary" style={{marginTop: '30px'}}>
                    Save
                </Button>
            </div>
        );
    }

    onSubmit() {
        //Send new settings to server
        //{"device_id":"1000200030004000","air_temperature_warning_min":"30","air_temperature_warning_max":"100","air_temperature_emergency_min":"10",
        // "air_temperature_emergency_max":"120","water_temperature_warning_min":"30","water_temperature_warning_max":"100",
        // "water_temperature_emergency_min":"10","water_temperature_emergency_max":"120","water_level_warning_min":"80",
        // "water_level_warning_max":"100","water_level_emergency_min":"50","water_level_emergency_max":"120",
        // "uv_level_warning_min":"6","uv_level_warning_max":"8","uv_level_emergency_min":"0",
        // "uv_level_emergency_max":"100","hours_of_light_needed":"9","feed_fish_at":"12","take_picture_every":"12"}}`);

        //TODO: Checked against session
        let newSettings = {"device_id": this.state.device_id};
        this.state.settings.forEach(e => {
            let varname = e.name.toLowerCase().split(' ').join('_');
            if (e.hasOwnProperty('value')) {
                newSettings[`${varname}`] = e.value;
            } else {
                newSettings[`${varname}_warning_min`] = e.range.warning.min;
                newSettings[`${varname}_warning_max`] = e.range.warning.max;
                newSettings[`${varname}_emergency_min`] = e.range.emergency.min;
                newSettings[`${varname}_emergency_max`] = e.range.emergency.max;
            }
        });
        console.log("New Settings: ", newSettings);
        fetch("https://hookedonaquaponics.ece4012.gatech.edu/updateSettings.php", {
            method: 'POST',
            body: JSON.stringify(newSettings),
            credentials: "include",
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response => {
            if (response.status !== 200) {
                console.log(`An error occurred. Status code: ${response.status}`);
                this.setState({
                    errorMessage: `An error occurred. Status code: ${response.status}`,
                });
            } else {
                let parent = this;
                response.json().then(function (res) {
                    console.log(res);
                    if (res.hasOwnProperty('success') && res.success) {
                        //TODO: Show success toast
                        if (res.hasOwnProperty('error')) {
                            parent.setState({
                                errorMessage: res.error,
                            });
                        } else {
                            parent.setState({
                                errorMessage: "Changes Saved",
                            });
                        }
                    } else {
                        parent.setState({
                            errorMessage: res.error,
                        });
                    }
                });
            }
        });
    }

    fetchSettings() {
        fetch("https://hookedonaquaponics.ece4012.gatech.edu/settings.php", {
            method: 'POST',
            body: ``,
            credentials: "include",
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            }
        }).then(response => {
            if (response.status !== 200) {
                console.log(`An error occurred. Status code: ${response.status}`);
                this.setState({
                    errorMessage: `An error occurred. Status code: ${response.status}`,
                });
            } else {
                let parent = this;
                //DEBUG: {"success":true,"email":"ece4012hoa@gmail.com","settings":{"device_id":"1000200030004000","air_temperature_warning_min":"30","air_temperature_warning_max":"100","air_temperature_emergency_min":"10","air_temperature_emergency_max":"120","water_temperature_warning_min":"30","water_temperature_warning_max":"100","water_temperature_emergency_min":"10","water_temperature_emergency_max":"120","water_level_warning_min":"100","water_level_warning_max":"300","water_level_emergency_min":"50","water_level_emergency_max":"350","hours_of_light_needed":"9","feed_fish_at":"12","take_picture_every":"12"}}
                response.json().then(function (res) {
                    console.log(res);
                    if (!res.hasOwnProperty('settings')) {
                        //Retry?
                    } else {
                        parent.setState({
                            device_id: res.settings.device_id,
                            settings: [
                                {
                                    name: 'Air Temperature',
                                    range: {
                                        warning: {
                                            min: res.settings.air_temperature_warning_min,
                                            max: res.settings.air_temperature_warning_max
                                        },
                                        emergency: {
                                            min: res.settings.air_temperature_emergency_min,
                                            max: res.settings.air_temperature_emergency_max
                                        }
                                    }
                                },
                                {
                                    name: 'Water Temperature',
                                    range: {
                                        warning: {
                                            min: res.settings.water_temperature_warning_min,
                                            max: res.settings.water_temperature_warning_max
                                        },
                                        emergency: {
                                            min: res.settings.water_temperature_emergency_min,
                                            max: res.settings.water_temperature_emergency_max
                                        }
                                    }
                                },
                                {
                                    name: 'Water Level',
                                    range: {
                                        warning: {
                                            min: res.settings.water_level_warning_min,
                                            max: res.settings.water_level_warning_max
                                        },
                                        emergency: {
                                            min: res.settings.water_level_emergency_min,
                                            max: res.settings.water_level_emergency_max
                                        }
                                    }
                                },
                                {
                                    name: 'UV Level',
                                    range: {
                                        warning: {
                                            min: res.settings.uv_level_warning_min,
                                            max: res.settings.uv_level_warning_max
                                        },
                                        emergency: {
                                            min: res.settings.uv_level_emergency_min,
                                            max: res.settings.uv_level_emergency_max
                                        }
                                    }
                                },
                                {
                                    name: 'Hours of Light Needed',
                                    value: res.settings.hours_of_light_needed
                                },
                            ]
                        });
                    }
                    parent.setState({res: JSON.stringify(res)});
                });
            }
        });
    }
}

class Settingslist extends Component {
    cardStyle = () => {
        return {
            backgroundColor: '#F3F3F3',
            width: '75%'
        }
    };

    render() {
        return (
            <Grid style={{maxWidth: '100%'}} container direction='column' alignItems='center' spacing={1}
                  justify='center'>
                {this.props.settings.map((setting) => {
                    if (setting.hasOwnProperty('value')) {
                        return (<Card style={this.cardStyle()}><TextBoxSetting setting={setting}/></Card>)
                    }
                    return (<Card style={this.cardStyle()}><DoubleSliderSetting setting={setting}/></Card>)
                })}
            </Grid>
        );
    };
}


export function TextBoxSetting(props) {

    function handleValueChange(e) {
        if( /^\d+$/.test(e.target.value)) {
            props.setting.value = e.target.value;
        } else {
            e.target.value = props.setting.value;
        }
    }

    return (
        <div style={{marginTop: "2vw", marginBottom: "2vw", alignItems: 'center', justifyContent: 'center'}}>
            <TextField variant={"outlined"} label={props.setting.name} type={number}
                       onChange={handleValueChange} defaultValue={props.setting.value}/>
        </div>
    )
}

export function DoubleSliderSetting(props) {
    const [warningRange, setWarningRange] = useState([props.setting.range.warning.min, props.setting.range.warning.max]);
    const [emergencyRange, setEmergencyRange] = useState([props.setting.range.emergency.min, props.setting.range.emergency.max]);

    props.setting.range.warning.min = warningRange[0];
    props.setting.range.warning.max = warningRange[1];
    props.setting.range.emergency.min = emergencyRange[0];
    props.setting.range.emergency.max = emergencyRange[1];

    function handleWarningChange(e, val) {
        setWarningRange(val);
    }

    function handleEmergencyChange(e, val) {
        setEmergencyRange(val);
    }

    return (
        <Grid container item xs={12} style={{marginTop: "2vw", marginBottom: "2vw"}} alignItems='center'
              direction='row' justify='center'>
            <Grid item xs={3}>
                <p><strong>{props.setting.name}</strong></p>
            </Grid>
            <Grid item xs={3}>
                <Slider
                    value={warningRange}
                    onChange={handleWarningChange}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                />
                <Slider
                    value={emergencyRange}
                    onChange={handleEmergencyChange}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                />
            </Grid>
            <Grid item xs={6}>
                <p><strong>Warning Min: </strong>{warningRange[0]}
                    <strong> Warning Max: </strong>{warningRange[1]}</p>

                <p><strong>Emergency Min: </strong>{emergencyRange[0]}
                    <strong> Emergency Max: </strong>{emergencyRange[1]}</p>
            </Grid>
        </Grid>
    )
}

export default Settings;