//Dependencies
import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
//Screens
import Login from "./Login.js";
import Register from "./Register.js";
import Status from "./Status.js";
import Settings from "./Settings.js";
//Assets
import './res/App.css';
import RefreshIcon from './res/refresh.svg';
import InstallIcon from './res/install.svg';
import SettingsIcon from '@material-ui/icons/Settings';
import BackArrow from '@material-ui/icons/KeyboardBackspace';
import {Route, HashRouter as Router, Switch, NavLink} from "react-router-dom";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {showInstall: false, icon: ''};
        this.deferredInstallPrompt = null;
        this.saveBeforeInstallPromptEvent = this.saveBeforeInstallPromptEvent.bind(this);
        this.installPWA = this.installPWA.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
        this.navButton = this.navButton.bind(this);
        window.addEventListener('beforeinstallprompt', this.saveBeforeInstallPromptEvent);
        window.addEventListener('appinstalled', this.logAppInstalled);
    }


    render() {
        return (
            <div className="App">
                <header className="App-header">
                    { //Modify settings
                        this.state.icon === 'settings' &&
                        <Button style={{position: "absolute", left: '0vw', width: '10vw'}}
                                id="buttonSettings"
                                onClick={() => {
                                    window.location.href = "/#/settings"
                                }} hidden>
                            <SettingsIcon style={{fill: "white"}}/>
                        </Button>}
                    { //Return to user status
                        this.state.icon === 'back' &&
                        <Button style={{position: "absolute", left: '0vw', width: '10vw'}}
                                id="buttonStatus"
                                onClick={() => {
                                    //We could just add another variable to navButton, but this is fine
                                    if(window.location.href.replace(window.location.origin, "").toLowerCase().includes("settings")) {
                                        window.location.href = "/#/status"
                                    } else {
                                        window.location.href = "/#/login"
                                    }
                                }} hidden>
                            <BackArrow style={{fill: "white"}}/>
                        </Button>}
                    <h1> Hooked on Aquaponics </h1>
                    {this.state.showInstall &&
                    <Button style={{position: "absolute", right: '10vw', width: '10vw'}}
                            id="buttonInstall" onClick={this.installPWA} hidden>
                        <img src={InstallIcon} alt="Install"/>
                    </Button>}
                    <Button style={{position: "absolute", right: '0', width: '10vw'}} id="buttonRefresh"
                            aria-label="Refresh" onClick={this.refreshPage}>
                        <img src={RefreshIcon} alt="Refresh"/>
                    </Button>
                </header>
                <body>
                <Router>
                    {/*<NavLink to="/login" style={{marginRight: '10px'}}>Login</NavLink>*/}
                    {/*<NavLink to="/status" style={{marginRight: '10px'}}>Status</NavLink>*/}
                    {/*<NavLink to="/settings">Settings</NavLink>*/}
                    <Switch>
                        <Route exact path="/" component={this}>
                            <Login action={this.navButton}/>
                        </Route>
                        <Route path="/login"><Login action={this.navButton}/></Route>
                        <Route path="/register"><Register action={this.navButton}/></Route>
                        <Route path="/status"><Status action={this.navButton}/></Route>
                        <Route path="/settings"><Settings action={this.navButton}/></Route>
                    </Switch>
                </Router>
                </body>
            </div>
        );
    }

    refreshPage() {
        window.location.reload(true);
    }

    // Passed to children for updating icon
    navButton(newIcon) {
        this.setState({icon: newIcon});
    }

    /**
     * Event handler for beforeinstallprompt event.
     *   Saves the event & shows install button.
     * @param {Event} evt Event occurs when PWA is valid and not already installed
     */
    saveBeforeInstallPromptEvent(evt) {
        this.deferredInstallPrompt = evt;
        this.forceUpdate();
        this.setState({showInstall: true});
    }

    /**
     * Event handler for install button - Does the PWA installation.
     * @param {Event} evt
     */
    installPWA(evt) {
        console.log('Pushing install prompt');
        this.deferredInstallPrompt.prompt();
        // Hide the install button, it can't be called twice.
        this.setState({showInstall: false});
        // Log user response to prompt.
        this.deferredInstallPrompt.userChoice
            .then((choice) => {
                if (choice.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt', choice);
                } else {
                    console.log('User dismissed the A2HS prompt', choice);
                }
                this.deferredInstallPrompt = null;
            });
    }

    /**
     * Event handler for appinstalled event.
     *   Log the installation to analytics or save the event somehow.
     * @param {Event} evt
     */
    logAppInstalled(evt) {
        console.log('Aquaponic App was installed.', evt);
    }
}

export default App;