import React from "react";
import {Grid, Button, TextField} from '@material-ui/core';
import "./res/Login.css";
import AccountCircle from '@material-ui/icons/AccountCircle';
import VpnKey from '@material-ui/icons/VpnKey';
import logo from "./res/logo512.png";

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            invalidEmail: false,
            invalidPassword: false,
            emailValue: '',
            passwordValue: '',
            errorMessage: ''
        };
        this.props.action('');
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    render() {
        return (
            <div className="Login">
                <img src={logo} className="App-logo" alt="logo"/>
                {this.state.errorMessage.length === 0 ? null :
                    <div style={{marginBottom: '30px', color: 'red'}}>{this.state.errorMessage}</div>}
                <div>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <AccountCircle/>
                        </Grid>
                        <Grid item>
                            <TextField id="email"
                                       label="Email"
                                       autofocus
                                       error={this.state.invalidEmail}
                                       onChange={this.onEmailChange}/>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <VpnKey/>
                        </Grid>
                        <Grid item>
                            <TextField id="password"
                                       label="Password"
                                       type="password"
                                       error={this.state.invalidPassword}
                                       onChange={this.onPasswordChange}/>
                        </Grid>
                    </Grid>
                </div>
                <Button onClick={this.onSubmit} variant="contained" color="primary" style={{marginTop: '30px'}}>
                    Login
                </Button>
                <a href="/#/register">Register</a>
            </div>
        );
    }

    onEmailChange(e) {
        this.setState({
            invalidEmail: false,
            emailValue: e.target.value
        });
    }

    onPasswordChange(e) {
        this.setState({
            invalidPassword: false,
            passwordValue: e.target.value
        });
    }

    onSubmit() {
        if (this.state.emailValue.trim().length === 0) {
            console.log("Invalid Email: Empty");
            this.setState({
                invalidEmail: true
            });
            return;
        }
        if (this.state.passwordValue.trim().length === 0) {
            console.log("Invalid Password: Empty");
            this.setState({
                invalidPassword: true
            });
            return;
        }
        // eslint-disable-next-line no-useless-escape
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.emailValue.trim())) {
            console.log("Invalid Email: Regex mismatch");
            this.setState({
                invalidEmail: true
            });
            return;
        }
        fetch("https://hookedonaquaponics.ece4012.gatech.edu/login.php", {
            method: 'POST',
            body: `email=${this.state.emailValue.trim()}&password=${this.state.passwordValue.trim()}`,
            credentials: "include",
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            }
        }).then(response => {
            if (response.status !== 200) {
                console.log(`An error occurred. Status code: ${response.status}`);
                this.setState({
                    errorMessage: `An error occurred. Status code: ${response.status}`,
                });
            } else {
                let parent = this;
                response.json().then(function (res) {
                    console.log(res);
                    console.log("Showing success/errors");
                    if (res.hasOwnProperty('success') && res.success) {
                        console.log("Success");
                        //Session loads email on next page
                        window.location.href="/#/status";
                    } else {
                        //Show user the error
                        if (res.hasOwnProperty('error')) {
                            if (res.error.includes("Invalid Email")) {
                                parent.setState({
                                    invalidEmail: true
                                });
                            } else if (res.error.includes("Invalid Password")) {
                                parent.setState({
                                    invalidPassword: true
                                });
                            }
                            parent.setState({
                                errorMessage: res.error,
                            });
                        } else {
                            //TODO: Generic error message
                            parent.setState({
                                errorMessage: "Error",
                            });
                        }
                    }
                });
            }
        });
    }
}

export default Login;