import React from "react";
import "./res/Register.css";
import {Grid, Button, TextField} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import VpnKey from '@material-ui/icons/VpnKey';
import DeviceIcon from '@material-ui/icons/PermDeviceInformation';
import Phone from '@material-ui/icons/Phone';

class Register extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            invalidEmail: false,
            invalidPassword: false,
            invalidPasswordConfirm: false,
            invalidDeviceID: false,
            invalidPhoneNumber: false,
            invalidPhoneNumber2: false,
            emailValue: '',
            passwordValue: '',
            passwordConfirmValue: '',
            deviceIDValue: '',
            phoneNumberValue: '',
            phoneNumber2Value: '',
            errorMessage: ''
        };
        this.props.action('back');
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onPasswordConfirmChange = this.onPasswordConfirmChange.bind(this);
        this.onDeviceIDChange = this.onDeviceIDChange.bind(this);
        this.onPhoneNumberChange = this.onPhoneNumberChange.bind(this);
        this.onPhoneNumber2Change = this.onPhoneNumber2Change.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    render() {
        return (
            <div className="Register">
                <h1>Register</h1>
                {this.state.errorMessage.length === 0 ? null :
                    <div style={{marginBottom: '30px', color: 'red'}}>{this.state.errorMessage}</div>}
                <div>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <AccountCircle/>
                        </Grid>
                        <Grid item>
                            <TextField id="email"
                                       label="Email"
                                       autofocus
                                       error={this.state.invalidEmail}
                                       onChange={this.onEmailChange}/>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <VpnKey/>
                        </Grid>
                        <Grid item>
                            <TextField id="password"
                                       label="Password"
                                       type="password"
                                       error={this.state.invalidPassword}
                                       onChange={this.onPasswordChange}/>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <VpnKey/>
                        </Grid>
                        <Grid item>
                            <TextField id="passwordConfirm"
                                       label="Confirm Password"
                                       type="password"
                                       error={this.state.invalidConfirmPassword}
                                       onChange={this.onPasswordConfirmChange}/>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <DeviceIcon/>
                        </Grid>
                        <Grid item>
                            <TextField id="deviceID"
                                       label="Device ID"
                                       type="number"
                                       error={this.state.invalidDeviceID}
                                       onChange={this.onDeviceIDChange}/>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <Phone/>
                        </Grid>
                        <Grid item>
                            <TextField id="phoneNumber"
                                       label="Phone Number"
                                       type="tel"
                                       error={this.state.invalidPhoneNumber}
                                       onChange={this.onPhoneNumberChange}/>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <Phone/>
                        </Grid>
                        <Grid item>
                            <TextField id="phoneNumber2"
                                       label="Phone Number Alt (O)"
                                       type="tel"
                                       error={this.state.invalidPhoneNumber2}
                                       onChange={this.onPhoneNumber2Change}/>
                        </Grid>
                    </Grid>
                </div>
                <Button onClick={this.onSubmit} variant="contained" color="primary" style={{marginTop: '30px'}}>
                    Register
                </Button>
                {/*<a href="/#/login">Login</a>*/}
            </div>
        );
    }

    onEmailChange(e) {
        this.setState({
            invalidEmail: false,
            emailValue: e.target.value
        });
    }

    onPasswordChange(e) {
        this.setState({
            invalidPassword: false,
            passwordValue: e.target.value
        });
    }

    onPasswordConfirmChange(e) {
        this.setState({
            invalidConfirmPassword: false,
            passwordConfirmValue: e.target.value
        });
    }

    onDeviceIDChange(e) {
        this.setState({
            invalidDeviceID: false,
            deviceIDValue: e.target.value
        });
    }

    onPhoneNumberChange(e) {
        this.setState({
            invalidPhoneNumber: false,
            phoneNumberValue: e.target.value
        });
    }

    onPhoneNumber2Change(e) {
        this.setState({
            invalidPhoneNumber2: false,
            phoneNumber2Value: e.target.value
        });
    }

    onSubmit() {
        this.setState({
            errorMessage: ''
        });
        if (this.state.emailValue.trim().length === 0) {
            console.log("Invalid Email: Empty");
            this.setState({
                errorMessage: "Email is required",
                invalidEmail: true
            });
            return;
        }
        if (this.state.passwordValue.trim().length === 0) {
            console.log("Invalid Password: Empty");
            this.setState({
                errorMessage: "Password is required",
                invalidPassword: true
            });
            return;
        }
        //Password confirm must match anyway
        if (this.state.deviceIDValue.trim().length === 0) {
            console.log("Invalid Device ID: Empty");
            this.setState({
                errorMessage: "Device ID is required",
                invalidDeviceID: true
            });
            return;
        }
        if (this.state.phoneNumberValue.trim().length === 0) {
            console.log("Invalid Phone Number: Empty");
            this.setState({
                errorMessage: "Phone Number is required",
                invalidPhoneNumber: true
            });
            return;
        }
        //Phone alt is optional
        // eslint-disable-next-line no-useless-escape
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.emailValue.trim())) {
            console.log("Invalid Email: Regex mismatch");
            this.setState({
                errorMessage: "Invalid Email",
                invalidEmail: true
            });
            return;
        }
        if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$/.test(this.state.passwordValue.trim())) { //.{7,}
            console.log("Invalid Password: Regex mismatch");
            this.setState({
                errorMessage: "Password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 digit",
                invalidPassword: true
            });
            return;
        }
        if (this.state.passwordValue.trim().length < 7) {
            console.log("Invalid Password: Too Short");
            this.setState({
                errorMessage: "Password must be at least 7 characters long",
                invalidPassword: true
            });
            return;
        }
        if (this.state.passwordValue !== this.state.passwordConfirmValue.trim()) {
            console.log("Invalid Password Confirm: Password doesn't match");
            this.setState({
                errorMessage: "Passwords must match",
                invalidConfirmPassword: true
            });
            return;
        }
        if (!/\d{16}/.test(this.state.deviceIDValue.trim())) {
            console.log("Invalid DeviceID: Regex mismatch");
            this.setState({
                errorMessage: "Device ID is 16 digits",
                invalidDeviceID: true
            });
            return;
        }
        if (!/[1-9]\d{9}/.test(this.state.phoneNumberValue.replace("[^\\d]", ""))) {
            console.log("Invalid Phone Number: Regex mismatch");
            this.setState({
                errorMessage: "US Phone number is invalid",
                invalidPhoneNumber: true
            });
            return;
        }
        if (this.state.phoneNumber2Value.length > 0 && !/[1-9]\d{9}/.test(this.state.phoneNumber2Value.replace("[^\\d]", ""))) {
            console.log("Invalid Phone Number Alt: Regex mismatch");
            this.setState({
                errorMessage: "US Phone number alt is invalid",
                invalidPhoneNumber2: true
            });
            return;
        }
        fetch("https://hookedonaquaponics.ece4012.gatech.edu/register.php", {
            method: 'POST',
            body: `email=${this.state.emailValue.trim()
            }&password=${this.state.passwordValue.trim()
            }&password_confirm=${this.state.passwordConfirmValue.trim()
            }&device_id=${this.state.deviceIDValue.trim()
            }&phone_number=${this.state.phoneNumberValue.replace("[^\\d]", "")
            }&phone_number_alt=${this.state.phoneNumber2Value.replace("[^\\d]", "")}`,
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            }
        }).then(response => {
            if (response.status !== 200) {
                console.log(`An error occurred. Status code: ${response.status}`);
                this.setState({
                    errorMessage: `An error occurred. Status code: ${response.status}`,
                });
            } else {
                let parent = this;
                response.json().then(function (res) {
                    console.log(res);
                    console.log("Showing success/errors");
                    if (res.hasOwnProperty('success') && res.success) {
                        console.log("Success");
                        parent.setState({
                            errorMessage: `Registration Success!`,
                        });
                        window.location.href = "/#/login";
                    } else {
                        //Show user the error
                        if (res.hasOwnProperty('error')) {
                            if (res.error.toLowerCase().includes("email")) {
                                parent.setState({
                                    invalidEmail: true
                                });
                            } else if (res.error.toLowerCase().includes("password")) {
                                //Password confirm should never happen
                                parent.setState({
                                    invalidPassword: true
                                });
                            } else if (res.error.toLowerCase().includes("device")) {
                                parent.setState({
                                    invalidDeviceID: true
                                });
                            } else if (res.error.toLowerCase().includes("alternate phone number")) {
                                parent.setState({
                                    invalidPhoneNumber2: true
                                });
                            } else if (res.error.toLowerCase().includes("phone number")) {
                                parent.setState({
                                    invalidPhoneNumber: true
                                });
                            }
                            parent.setState({
                                errorMessage: res.error,
                            });
                        } else {
                            //TODO: Generic error message
                            parent.setState({
                                errorMessage: "Error",
                            });
                        }
                    }
                });
            }
        });
    }
}

export default Register;

// export default () => {
//     const {handleSubmit, register, errors} = useForm();
//     const onSubmit = values => {
//         console.log(values);
//         console.log(values.email);
//     };
//
//     return (
//         <div className="Register">
//             <form onSubmit={handleSubmit(onSubmit)}>
//                 <input name="email"
//                        ref={register({
//                            required: 'Required',
//                            pattern: {
//                                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
//                                message: "Invalid email address"
//                            }
//                        })}
//                 />
//                 {errors.email && errors.email.message}
//
//                 <input name="password"
//                        ref={register({
//                            required: 'Required',
//                            pattern: {
//                                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,}$/,
//                                message: "Must contain at least: 1 uppercase letter, 1 lowercase letter, 1 digit\nMust be at least 7 characters long"
//                            }
//                        })}
//                 />
//                 {errors.password && errors.email.password}
//
//                 <input name="device_id"
//                        ref={register({
//                            required: 'Required',
//                            pattern: {
//                                value: /\d{16}/,
//                                message: "Must be 16 digits"
//                            }
//                        })}
//                 />
//                 {errors.device_id && errors.device_id.message}
//             </form>
//             <Button onClick={onSubmit} variant="contained" color="primary" style={{marginTop: '30px'}}>
//                 Register
//             </Button>
//         </div>
//     );
// };
